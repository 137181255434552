@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* login */
.form-block {
  background: #fff;
  padding: 40px;
  max-width: 400px;
  -webkit-box-shadow: 0 15px 30px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 20%);
  margin: 0 auto;
  margin-top: 10%;
}
.form-block .bp3-input{
  background: #efefef;
    box-shadow: none;
    border: none;
    border-radius: 4px;
    height: 48px;
}
.form-block button{
  align-self: center;
    color: #fff !important;
    background-color: #fb771a !important;
    border-color: #fb771a;
    width: 100%;
    box-shadow: none !important;
    height: 44px;
    font-size: 18px;
    margin-top: 20px;
    background-image: none !important;
}

.bp3-html-table td {
  border-bottom: 1px solid #ddd;
}
.bp3-html-table{
  border: 1px solid #ddd;
}
.bp3-html-table thead{
  background: #f0f0f0;
}
.password_field{
  border-bottom: 1px solid #c6c6c6  !important;
}
.new-pagination button{
  padding: 10px !important;
    height: 38px;
    font-weight: 600;
    outline: none;
}
.new-pagination .pagination, .new-pagination .pagination:hover{
  background-color: #177ebe;
  color: #fff;
  background-image: none;
}